//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CheckboxInput',

  props: {
    value: {
      type: [Array],
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    subType: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    checkboxModel: null
  }),

  watch: {
    value: {
      immediate: true,
      handler (val) {
        if (this.checkboxModel !== val) {
          this.checkboxModel = val
        }
      }
    }
  },

  methods: {
    updateValue ($event) {
      this.$emit('input', $event)
    }
  }
}
