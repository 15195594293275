export const AnimatedLink = () => import('../../components/global/AnimatedLink.vue' /* webpackChunkName: "components/animated-link" */).then(c => wrapFunctional(c.default || c))
export const ChangeLanguageLink = () => import('../../components/global/ChangeLanguageLink.vue' /* webpackChunkName: "components/change-language-link" */).then(c => wrapFunctional(c.default || c))
export const Chevron = () => import('../../components/global/Chevron.vue' /* webpackChunkName: "components/chevron" */).then(c => wrapFunctional(c.default || c))
export const GridContainer = () => import('../../components/global/GridContainer.vue' /* webpackChunkName: "components/grid-container" */).then(c => wrapFunctional(c.default || c))
export const Hamburger = () => import('../../components/global/Hamburger.vue' /* webpackChunkName: "components/hamburger" */).then(c => wrapFunctional(c.default || c))
export const Intersect = () => import('../../components/global/Intersect.vue' /* webpackChunkName: "components/intersect" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/global/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const PageSection = () => import('../../components/global/PageSection.vue' /* webpackChunkName: "components/page-section" */).then(c => wrapFunctional(c.default || c))
export const PageWrapper = () => import('../../components/global/PageWrapper.vue' /* webpackChunkName: "components/page-wrapper" */).then(c => wrapFunctional(c.default || c))
export const PatternDiagonalLines = () => import('../../components/global/PatternDiagonalLines.vue' /* webpackChunkName: "components/pattern-diagonal-lines" */).then(c => wrapFunctional(c.default || c))
export const PatternDot = () => import('../../components/global/PatternDot.vue' /* webpackChunkName: "components/pattern-dot" */).then(c => wrapFunctional(c.default || c))
export const StatusBadge = () => import('../../components/global/StatusBadge.vue' /* webpackChunkName: "components/status-badge" */).then(c => wrapFunctional(c.default || c))
export const TextButton = () => import('../../components/global/TextButton.vue' /* webpackChunkName: "components/text-button" */).then(c => wrapFunctional(c.default || c))
export const FormGroup = () => import('../../components/global/form/FormGroup.vue' /* webpackChunkName: "components/form-group" */).then(c => wrapFunctional(c.default || c))
export const FormSection = () => import('../../components/global/form/FormSection.vue' /* webpackChunkName: "components/form-section" */).then(c => wrapFunctional(c.default || c))
export const FormFieldsCheckboxInput = () => import('../../components/global/form/fields/CheckboxInput.vue' /* webpackChunkName: "components/form-fields-checkbox-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldsDateInput = () => import('../../components/global/form/fields/DateInput.vue' /* webpackChunkName: "components/form-fields-date-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldsDatetimeInput = () => import('../../components/global/form/fields/DatetimeInput.vue' /* webpackChunkName: "components/form-fields-datetime-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldsFileInput = () => import('../../components/global/form/fields/FileInput.vue' /* webpackChunkName: "components/form-fields-file-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldsNumberInput = () => import('../../components/global/form/fields/NumberInput.vue' /* webpackChunkName: "components/form-fields-number-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldsPercentageInput = () => import('../../components/global/form/fields/PercentageInput.vue' /* webpackChunkName: "components/form-fields-percentage-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldsRadioInput = () => import('../../components/global/form/fields/RadioInput.vue' /* webpackChunkName: "components/form-fields-radio-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldsRelationInput = () => import('../../components/global/form/fields/RelationInput.vue' /* webpackChunkName: "components/form-fields-relation-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldsSelectInput = () => import('../../components/global/form/fields/SelectInput.vue' /* webpackChunkName: "components/form-fields-select-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldsTermInput = () => import('../../components/global/form/fields/TermInput.vue' /* webpackChunkName: "components/form-fields-term-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldsTextInput = () => import('../../components/global/form/fields/TextInput.vue' /* webpackChunkName: "components/form-fields-text-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldsTextareaInput = () => import('../../components/global/form/fields/TextareaInput.vue' /* webpackChunkName: "components/form-fields-textarea-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldsUppyModal = () => import('../../components/global/form/fields/UppyModal.vue' /* webpackChunkName: "components/form-fields-uppy-modal" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/app/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppFooterNav = () => import('../../components/app/AppFooterNav.vue' /* webpackChunkName: "components/app-footer-nav" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/app/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppLocker = () => import('../../components/app/AppLocker.vue' /* webpackChunkName: "components/app-locker" */).then(c => wrapFunctional(c.default || c))
export const AppMainMenu = () => import('../../components/app/AppMainMenu.vue' /* webpackChunkName: "components/app-main-menu" */).then(c => wrapFunctional(c.default || c))
export const AppMainMenuItem = () => import('../../components/app/AppMainMenuItem.vue' /* webpackChunkName: "components/app-main-menu-item" */).then(c => wrapFunctional(c.default || c))
export const AppNavbar = () => import('../../components/app/AppNavbar.vue' /* webpackChunkName: "components/app-navbar" */).then(c => wrapFunctional(c.default || c))
export const AuthAccountForm = () => import('../../components/auth/AccountForm.vue' /* webpackChunkName: "components/auth-account-form" */).then(c => wrapFunctional(c.default || c))
export const AuthChangePasswordForm = () => import('../../components/auth/ChangePasswordForm.vue' /* webpackChunkName: "components/auth-change-password-form" */).then(c => wrapFunctional(c.default || c))
export const AuthDeleteAccountForm = () => import('../../components/auth/DeleteAccountForm.vue' /* webpackChunkName: "components/auth-delete-account-form" */).then(c => wrapFunctional(c.default || c))
export const AuthForgotPasswordForm = () => import('../../components/auth/ForgotPasswordForm.vue' /* webpackChunkName: "components/auth-forgot-password-form" */).then(c => wrapFunctional(c.default || c))
export const AuthResetPasswordForm = () => import('../../components/auth/ResetPasswordForm.vue' /* webpackChunkName: "components/auth-reset-password-form" */).then(c => wrapFunctional(c.default || c))
export const AuthSendInviteForm = () => import('../../components/auth/SendInviteForm.vue' /* webpackChunkName: "components/auth-send-invite-form" */).then(c => wrapFunctional(c.default || c))
export const AuthSigninForm = () => import('../../components/auth/SigninForm.vue' /* webpackChunkName: "components/auth-signin-form" */).then(c => wrapFunctional(c.default || c))
export const AuthSignupForm = () => import('../../components/auth/SignupForm.vue' /* webpackChunkName: "components/auth-signup-form" */).then(c => wrapFunctional(c.default || c))
export const AuthVerifyEmailForm = () => import('../../components/auth/VerifyEmailForm.vue' /* webpackChunkName: "components/auth-verify-email-form" */).then(c => wrapFunctional(c.default || c))
export const CompanyRelationForm = () => import('../../components/company/RelationForm.vue' /* webpackChunkName: "components/company-relation-form" */).then(c => wrapFunctional(c.default || c))
export const CompanyServicesForm = () => import('../../components/company/ServicesForm.vue' /* webpackChunkName: "components/company-services-form" */).then(c => wrapFunctional(c.default || c))
export const CompanyStatusBox = () => import('../../components/company/StatusBox.vue' /* webpackChunkName: "components/company-status-box" */).then(c => wrapFunctional(c.default || c))
export const CompanyVendorRegistrationForm = () => import('../../components/company/VendorRegistrationForm.vue' /* webpackChunkName: "components/company-vendor-registration-form" */).then(c => wrapFunctional(c.default || c))
export const LoadersLogoLoader = () => import('../../components/loaders/LogoLoader.vue' /* webpackChunkName: "components/loaders-logo-loader" */).then(c => wrapFunctional(c.default || c))
export const TransitionsCustomTransition = () => import('../../components/transitions/CustomTransition.vue' /* webpackChunkName: "components/transitions-custom-transition" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
