//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'FormGroup',
  props: {
    value: {},
    /**
     * Field (input) config
     * @type {Object}
     */
    field: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    subType: '',
    currValue: '',
    validatorSync: true,
    helpTop: false,
    defaults: {
      helpText: '',
      options: {
        placeholder: '',
        class: '',
        disabled: false,
        readonly: false
      }
    }
  }),

  computed: {
    $_field () {
      return Object.assign({}, this.defaults, this.field)
    }
  },

  watch: {
    value (val) {
      this.currValue = val
    },

    field: {
      deep: true,
      immediate: true,
      handler (val) {
        if (
          this.field.options.type === 'relation' ||
          this.field.options.type === 'file'
        ) {
          this.helpTop = true
          return
        }

        this.helpTop = false
      }
    }
  },

  methods: {
    // validateField() {
    //   return this.$refs.validator.validate()
    // },

    getFieldComponent (field) {
      switch (field.options.type) {
        case 'boolean':
          field.options.options = [
            { value: 1, text: this.$t('form.booleanInput.trueLabel') },
            { value: 0, text: this.$t('form.booleanInput.falseLabel') }
          ]
          return 'RadioInput'
        case 'percentage':
          return 'PercentageInput'
        case 'textarea':
          return 'TextareaInput'
        case 'file':
          return 'FileInput'
        case 'select':
          return 'SelectInput'
        case 'checkbox':
          return 'CheckboxInput'
        case 'term':
          this.validatorSync = false
          return 'TermInput'
        case 'date':
          return 'DateInput'
        case 'datetime':
          return 'DateTimeInput'
        case 'relation':
          this.subType = field.options.entityType
          return 'RelationInput'
        case 'serviceRelation':
          return 'ServiceRelationInput'
        case 'number':
          this.subType = 'number'
          return 'TextInput'
        case 'integer':
          this.subType = 'integer'
          return 'NumberInput'
        case 'email':
          this.subType = 'email'
          return 'TextInput'
        case 'password':
          this.subType = 'password'
          return 'TextInput'
        case 'text':
        default:
          this.subType = 'text'
          return 'TextInput'
      }
    },

    onInput ($event) {
      if (this.validatorSync) {
        this.$refs.validator.syncValue($event)
      }
      this.$emit('input', $event)
    }
  }
}
