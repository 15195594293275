import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8dcc02e8 = () => interopDefault(import('../pages/account-settings.vue' /* webpackChunkName: "pages/account-settings" */))
const _ede5a242 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _4cc68d4e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e5a35b68 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _f9bb0fc0 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _564db2d4 = () => interopDefault(import('../pages/reset-link-sent.vue' /* webpackChunkName: "pages/reset-link-sent" */))
const _39354a7c = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _4dcba0f2 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _339b573d = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _88fb0d36 = () => interopDefault(import('../pages/vendor-registration.vue' /* webpackChunkName: "pages/vendor-registration" */))
const _8a14b51e = () => interopDefault(import('../pages/eoi/_id.vue' /* webpackChunkName: "pages/eoi/_id" */))
const _84a0deba = () => interopDefault(import('../pages/password-reset/_code.vue' /* webpackChunkName: "pages/password-reset/_code" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-settings",
    component: _8dcc02e8,
    name: "account-settings"
  }, {
    path: "/change-password",
    component: _ede5a242,
    name: "change-password"
  }, {
    path: "/en",
    component: _4cc68d4e,
    name: "index___en"
  }, {
    path: "/forgot-password",
    component: _e5a35b68,
    name: "forgot-password"
  }, {
    path: "/fr",
    component: _4cc68d4e,
    name: "index___fr"
  }, {
    path: "/privacy-policy",
    component: _f9bb0fc0,
    name: "privacy-policy"
  }, {
    path: "/reset-link-sent",
    component: _564db2d4,
    name: "reset-link-sent"
  }, {
    path: "/sign-in",
    component: _39354a7c,
    name: "sign-in"
  }, {
    path: "/sign-up",
    component: _4dcba0f2,
    name: "sign-up"
  }, {
    path: "/terms-and-conditions",
    component: _339b573d,
    name: "terms-and-conditions"
  }, {
    path: "/vendor-registration",
    component: _88fb0d36,
    name: "vendor-registration"
  }, {
    path: "/en/account-settings",
    component: _8dcc02e8,
    name: "account-settings___en"
  }, {
    path: "/en/change-password",
    component: _ede5a242,
    name: "change-password___en"
  }, {
    path: "/en/forgot-password",
    component: _e5a35b68,
    name: "forgot-password___en"
  }, {
    path: "/en/privacy-policy",
    component: _f9bb0fc0,
    name: "privacy-policy___en"
  }, {
    path: "/en/reset-link-sent",
    component: _564db2d4,
    name: "reset-link-sent___en"
  }, {
    path: "/en/sign-in",
    component: _39354a7c,
    name: "sign-in___en"
  }, {
    path: "/en/sign-up",
    component: _4dcba0f2,
    name: "sign-up___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _339b573d,
    name: "terms-and-conditions___en"
  }, {
    path: "/en/vendor-registration",
    component: _88fb0d36,
    name: "vendor-registration___en"
  }, {
    path: "/fr/changer-le-mot-de-passe",
    component: _ede5a242,
    name: "change-password___fr"
  }, {
    path: "/fr/enregistrement-des-vendeurs",
    component: _88fb0d36,
    name: "vendor-registration___fr"
  }, {
    path: "/fr/mot-de-passe-oublie",
    component: _e5a35b68,
    name: "forgot-password___fr"
  }, {
    path: "/fr/parametres-du-compte",
    component: _8dcc02e8,
    name: "account-settings___fr"
  }, {
    path: "/fr/politique-de-confidentialite",
    component: _f9bb0fc0,
    name: "privacy-policy___fr"
  }, {
    path: "/fr/reinitialiser-lien-envoye",
    component: _564db2d4,
    name: "reset-link-sent___fr"
  }, {
    path: "/fr/s-identifier",
    component: _39354a7c,
    name: "sign-in___fr"
  }, {
    path: "/fr/s-inscrire",
    component: _4dcba0f2,
    name: "sign-up___fr"
  }, {
    path: "/fr/termes-et-conditions",
    component: _339b573d,
    name: "terms-and-conditions___fr"
  }, {
    path: "/en/EOI/:id",
    component: _8a14b51e,
    name: "eoi-id___en"
  }, {
    path: "/en/password-reset/:code",
    component: _84a0deba,
    name: "password-reset-code___en"
  }, {
    path: "/fr/EOI/:id",
    component: _8a14b51e,
    name: "eoi-id___fr"
  }, {
    path: "/fr/reinitialisation-du-mot-de-passe/:code",
    component: _84a0deba,
    name: "password-reset-code___fr"
  }, {
    path: "/eoi/:id?",
    component: _8a14b51e,
    name: "eoi-id"
  }, {
    path: "/password-reset/:code?",
    component: _84a0deba,
    name: "password-reset-code"
  }, {
    path: "/",
    component: _4cc68d4e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
