import { render, staticRenderFns } from "./default.vue?vue&type=template&id=58206fd0&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLocker: require('/opt/buildhome/repo/components/app/AppLocker.vue').default,AppHeader: require('/opt/buildhome/repo/components/app/AppHeader.vue').default,AppMainMenu: require('/opt/buildhome/repo/components/app/AppMainMenu.vue').default,AppFooter: require('/opt/buildhome/repo/components/app/AppFooter.vue').default})
