import { render, staticRenderFns } from "./RelationForm.vue?vue&type=template&id=481047d1&scoped=true&"
import script from "./RelationForm.vue?vue&type=script&lang=js&"
export * from "./RelationForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "481047d1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormGroup: require('/opt/buildhome/repo/components/global/form/FormGroup.vue').default})
